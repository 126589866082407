import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { IntegerInputDirective, PriceSettings, Store, StoreSettingsService } from 'common';
import { BirthdayPartyAvailableSlot } from '../../../data/birthday-party-available-slot';
import { BirthdayPartyEvent } from '../../../data/birthday-party-event';
import { BirthdayPartySettings } from '../../../data/birthday-party-settings';
import { ThemeSelectorComponent } from "./theme-selector/theme-selector.component";

@Component({
  selector: 'app-birthday-party-booking',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, ThemeSelectorComponent, NgbTooltipModule, IntegerInputDirective],
  templateUrl: './birthday-party-booking.component.html',
  styleUrl: './birthday-party-booking.component.scss'
})
export class BirthdayPartyBookingComponent implements OnInit {

  @Input({ required: true })
  event: BirthdayPartyEvent

  private _store: Store
  storeSettings: BirthdayPartySettings;
  isAuthenticated: boolean;

  @Input({ required: true })
  set store(store: Store) {
    this._store = store;
    this.updateStoreSettings();
  }

  get store() {
    return this._store;
  }

  @Input({ required: true })
  slot: BirthdayPartyAvailableSlot

  @Output()
  onValidate = new EventEmitter<boolean>();

  private _price: number;

  get price() {
    return this._price || (this.event.onlineReduction ? this.globalPrice?.online : this.globalPrice?.other);
  }

  private _extraChildPrice: number;

  get extraChildPrice() {
    return this._extraChildPrice || this.globalPrice?.extraChild;
  }

  globalPrice: PriceSettings;

  birthdayPartyForm = this.fb.group({
    name: this.fb.control<string>(null, { validators: Validators.required }),
    age: this.fb.control<number>(null, { validators: Validators.required }),
    gender: this.fb.control<string>(null, { validators: Validators.required }),
    theme: this.fb.control<string>(null, { validators: Validators.required }),
    childrenCount: this.fb.control<number>(null, { validators: Validators.required }),
    language: this.fb.control<string>(null, { validators: Validators.required }),
    comment: this.fb.control<string>(null)
  });

  constructor(
    private fb: FormBuilder,
    private storeSettingsSvc: StoreSettingsService<BirthdayPartySettings>,
    private securityService: OidcSecurityService
  ) { }

  ngOnInit(): void {
    this.storeSettingsSvc.getGlobalPriceSettings().subscribe(globalPrice => this.globalPrice = globalPrice);
    this.securityService.isAuthenticated().subscribe(isAuthenticated => this.isAuthenticated = isAuthenticated);
  }

  updateStoreSettings() {
    this.storeSettingsSvc.getSettings(this.store.storeIdNumber).subscribe(
      storeSettings => {
        this.storeSettings = storeSettings;
        this.birthdayPartyForm.controls.age.setValidators([
          Validators.required,
          Validators.min(storeSettings.childAgeMin),
          Validators.max(storeSettings.childAgeMax)
        ]);

        this.birthdayPartyForm.controls.childrenCount.setValidators([
          Validators.required,
          Validators.max(storeSettings.childrenCountMax)
        ]);

        this.birthdayPartyForm.controls.language.setValue(storeSettings.languages.split(',')[0]);

        this._price = storeSettings.price;
        this._extraChildPrice = storeSettings.additionalChildPrice;
      }
    )
  }

  getPartyPrice() {
    let extraChildPricing = 0;
    if (this.birthdayPartyForm.controls.childrenCount.valid) {
      let extraChildrenCount = this.birthdayPartyForm.controls.childrenCount.value - 8;

      if (extraChildrenCount > 0) {
        extraChildPricing = extraChildrenCount * this.extraChildPrice;
      }
    }

    return this.price + extraChildPricing;
  }

  onSubmit() {
    if (this.birthdayPartyForm.valid) {
      const formValue = this.birthdayPartyForm.value;

      this.event.cakeType = "chocolate";
      this.event.theme = formValue.theme;
      this.event.child = {
        age: formValue.age,
        gender: formValue.gender,
        name: formValue.name
      };
      this.event.cards = {
        requested: false,
        sent: false,
        updateDate: null,
        teleperformanceName: null
      };
      this.event.childrenCount = formValue.childrenCount;
      this.event.language = formValue.language;
    }
    this.onValidate.emit(this.birthdayPartyForm.valid);
  }

}
